//モジュール---------------------------------------------------------------------------------------
// #region モジュール

//共通モジュール
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

//自前モジュール
import { TodoService } from 'src/app/services/todo.service';
import { environment } from 'src/environments/environment';

//必要モジュール
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { SelectionModel } from '@angular/cdk/collections';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { SnotifyService, SnotifyToast } from 'ng-snotify';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DateTimeAdapter } from 'ng-pick-datetime';
import { filter } from 'rxjs/operators';
import * as moment from 'moment';
import * as _ from "lodash";
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { GoogleMap, MapMarker, MapInfoWindow, MapGroundOverlay } from '@angular/google-maps';
import { url } from 'inspector';
import { elementEventFullName } from '@angular/compiler/src/view_compiler/view_compiler';
import { element } from 'protractor';
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { title } from 'process';
import { type } from 'os';

// #endregion

//インターフェイス---------------------------------------------------------------------------------
// #region インターフェイス

//インターフェイス
export interface MarkerInfo {
  areaName: string;
  mainSensorId: string;
  pointName: string;
  lat: number;
  lng: number;
  sensorName: string;
  turbidityNow: number;
  turbidityMax: number;
  dateTimeMax: string;
  turbidityPredict060: number;
  turbidityPredict120: number;
  turbidityPredict180: number;
  turbidityPredict240: number;
  turbidityPredict300: number;
  turbidityPredict360: number;
}

export interface pointDataTurbidity {
  checkedOrNot: boolean;
  PointDetail: string;
  cityName: string;
  areaName: string;
  sensorName: string;
  lat: number;
  lng: number;
  dateTime: string;
  mainSensorId: string;
  turbidityNow: number | string;
  turbidityMax: number | string;
  dateTimeMax: string;
  rainfallAnalytical: number | string;
  rainfallForecast060: number | string;
  rainfallForecast120: number | string;
  rainfallForecast180: number | string;
  rainfallForecast240: number | string;
  rainfallForecast300: number | string;
  rainfallForecast360: number | string;
  turbidityObserved: number | string;
  turbidityPredict060: number | string;
  turbidityPredict120: number | string;
  turbidityPredict180: number | string;
  turbidityPredict240: number | string;
  turbidityPredict300: number | string;
  turbidityPredict360: number | string;
}

export interface TurbidityAlertLevels {
  sensor: string | null;
  level1: number | null;
  level2: number | null;
  level3: number | null;
}
// #endregion

//グローバル変数-----------------------------------------------------------------------------------
// #region 定数定義
const ORANGE: string = "#F8CBAD";
const YELLOW: string = "#FFE699";
const BLUE: string = "#BDD7EE";

// #endregion

@Component({
  selector: 'app-map-turbidity',
  templateUrl: './map-turbidity.component.html',
  styleUrls: ['./map-turbidity.component.scss'],
})

export class MapTurbidityComponent implements OnInit {
  //メンバ変数-------------------------------------------------------------------------------------
  // #region 変数定義
  // コンポーネントへの参照を得る.
  // GoogleMap
  @ViewChild(GoogleMap, { static: false }) map!: GoogleMap;
  @ViewChild(MapMarker, { static: false }) marker!: MapMarker;
  @ViewChild(MapInfoWindow, { static: false }) infoWindow!: MapInfoWindow;
  @ViewChild(MapGroundOverlay, { static: false }) mapGroundOverlay!: MapGroundOverlay;
  // ソート機能
  @ViewChild(MatSort) sorter?: MatSort;
  // ページ機能
  @ViewChild(MatPaginator) paginator?: MatPaginator;

  //地図-----------------------------------------

  // オプション
  public options: any = {};

  //マーカー-------------------------------------

  // マーカー
  public markers: any[] = [];
  // 情報
  public markerInfos: MarkerInfo[] = [];
  // オプション
  public markerOption: any = {};
  // 座標
  public markerPositions: any[] = [];

  //雨量画像-------------------------------------

  // 画像url
  imageUrl: string = "";
  // 座標
  imageBounds: any;
  // 画像の日時&予測時刻
  rainfallImageTime: string = "";

  //センサープロット-----------------------------

  // プロットを全てon/offするflag
  headerCheckBox: boolean = true;
  // 各データのチェックボックス用
  forSortData: pointDataTurbidity[] = [];

  //カラム&タイトル------------------------------

  // 濁度&雨量リストのカラム名
  displayedColumns3 = ['select', 'PointDetail', 'areaName', 'sensorName', 'dateTime', 'turbidityNow', 'turbidityMax', 'dateTimeMax', 'turbidityPredict060',
    'turbidityPredict120', 'turbidityPredict180', 'turbidityPredict240', 'turbidityPredict300', 'turbidityPredict360', 'rainfallForecast060',
    'rainfallForecast120', 'rainfallForecast180', 'rainfallForecast240', 'rainfallForecast300', 'rainfallForecast360',];
  // マップタイトル
  titleLangArray: string[] = ["now Live Rainfall", "now 1 hours later Rainfall Forecast", "now 2 hours later Rainfall Forecast", "now 3 hours later Rainfall Forecast",
    "now 4 hours later Rainfall Forecast", "now 5 hours later Rainfall Forecast", "now 6 hours later Rainfall Forecast"];

  //angular--------------------------------------

  // 自動更新
  intervalId: number = 0;
  // angularのload
  loading = false;
  //日時絞り込み
  dataFormGroupTurbidity = new FormGroup({});
  // 日時指定されているか
  dateFilterMode: boolean = false;

  //その他---------------------------------------

  // 濁度&雨量データ
  sampleSource4: any; //濁度一覧用
  // ページ機能用のlength
  sampleSource4Length: number = 0;

  //アラート
  turbidityAlertLevels: TurbidityAlertLevels[] = [];

  // 時間計測
  startTime: number | null = null;
  endTime: number | null = null;

  // 事業者のcityId
  currentCityId: string | null = null;

  // #endregion

  //コンストラクタ・ライフサイクル-----------------------------------------------------------------
  // #region コンストラクタ・ライフサイクル

  // コンストラクタ
  constructor( private detailRouter: Router, public todoService: TodoService, private route: ActivatedRoute, private spinner: NgxSpinnerService, public http: HttpClient,
               private router: Router, private toastr: ToastrService, public cookieService: CookieService, private snotifyService: SnotifyService, private translate: TranslateService,
               private datePipe: DatePipe, private dateTimeAdapter: DateTimeAdapter<any>, iconRegistry: MatIconRegistry, public sanitizer: DomSanitizer) {
    this.outputLog("constructor", "start");

    // 
    if (sessionStorage.getItem('enableTurbidityMap') != "true") {
      this.detailRouter.navigate(['db/point-selection']);
    }

    // angularの何か?
    this.router.events
      .pipe(filter((rs): rs is NavigationEnd => rs instanceof NavigationEnd))
      .subscribe(event => {
        if (event.id === 1 && event.url === event.urlAfterRedirects) {
        }
      });

    // 日時指定
    this.dataFormGroupTurbidity = new FormGroup({
      datetime: new FormControl()
    });

    // 言語
    this.datePickerlang();

    // sessionとcookie確認
    this.outputLog("cookieService.getAll()", this.cookieService.getAll());
    this.outputLog("sessionStorage", sessionStorage);

    this.outputLog("constructor", "end");
  }

  ngOnChanges(): void {
    this.outputLog("ngOnChanges", "start");
    this.outputLog("ngOnChanges", "end");
  }

  ngOnInit(): void {
    this.outputLog("ngOnInit", "start");
    this.timePerformance();

    // angular load
    this.spinner.show();

    // 事業者(cityId)
    this.currentCityId = sessionStorage.getItem('cityId');

    // angular 自動(指定の間隔)で処理を繰り返す
    this.intervalId = Number(setInterval(() => {
      if (this.dateFilterMode == false) {
        this.timePerformance();
        this.getAllTurbidityAndRainfallListByCityid(new Date());
      }

      this.outputLog("自動更新", this.dateFilterMode == false);
    }, 300000));
    // 600000 = 10minute

    // 濁度閾値を取得
    this.getAlertLevel();

    this.outputLog("ngOnInit", "end");
  }

  ngDoCheck(): void {
    //this.outputLog("ngDoCheck", "start");
    //this.outputLog("ngDoCheck", "end");
  }

  ngAfterContentInit(): void {
    this.outputLog("ngAfterContentInit", "start");

    // 地図のオプション
    this.options.center = {
      lat: 35.697695,
      lng: 139.707354
    }
    this.options.zoom = 13;
    this.options.clickableIcons = true;
    this.options.disableDefaultUI = true;
    this.options.fullscreenControl = false;
    this.options.keyboardShortcuts = true;
    this.options.mapTypeControl = false;
    this.options.panControl = false;  //画面変化なし
    this.options.rotateControl = false; // 画面変化なし
    this.options.scaleControl = true;
    this.options.streetViewControl = false;
    this.options.zoomControl = false;
    this.options.mapTypeControlOptions = {
      position: window.google.maps.ControlPosition.TOP_RIGHT
    };
    this.options.streetViewControlOptions = {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    };
    this.options.zoomControlOptions = {
      position: window.google.maps.ControlPosition.RIGHT_BOTTOM
    };
    this.options.gestureHandling = "greedy";

    // マーカーオプション
    this.markerOption.icon = {
      url: "assets/point.png",
      scaledSize: new google.maps.Size(32, 32)
    }

    // 雨量画像貼り付け座標
    this.imageBounds = new window.google.maps.LatLngBounds();

    // 全センサーの濁度&雨量情報取得
    this.getAllTurbidityAndRainfallListByCityid(new Date());

    this.outputLog("ngAfterContentInit", "end");
  }

  ngAfterContentChecked(): void {
    //this.outputLog("ngAfterContentChecked", "start");
    //this.outputLog("ngAfterContentChecked", "end");
  }

  ngAfterViewInit(): void {
    this.outputLog("ngAfterViewInit", "start");
    this.outputLog("ngAfterViewInit", "end");
  }

  ngAfterViewChecked(): void {
    //this.outputLog("ngAfterViewChecked", "start");
    //this.outputLog("ngAfterViewChecked", "end");
  }

  ngOnDestroy(): void {
    this.outputLog("ngOnDestroy", "start");
    this.outputLog("ngOnDestroy", "end");
  }

  // #endregion

  //angular--------------------------------------

  // 翻訳機能用
  datePickerlang(): void {
    let lang : string | null = sessionStorage.getItem("lang");

    if(lang == null || lang == "" || lang == undefined){
      lang = this.cookieService.get('language');
    }

    this.todoService.languageData.subscribe((data: any) => {

      if (data == 'jp') {
        this.dateTimeAdapter.setLocale('ja-JP');
      }
      else if (data == 'en') {
        this.dateTimeAdapter.setLocale('en');
      }
      else {
        if (lang) {
          if (lang == 'en') {
            this.dateTimeAdapter.setLocale('en');
          }

          if (lang == 'jp') {
            this.dateTimeAdapter.setLocale('ja-JP');
          }
        }
      }
    });
  }

  //濁度閾値取得---------------------------------

  // 全センサーの濁度閾値を取得
  //   ・現状level3は仕様上使われない ( 0が送られて来る
  getAlertLevel(): void {
    this.todoService.getAllTurbidityThresholdValues().subscribe((response: any) => {
      if (response.length == 0) {
        this.spinner.hide();
        return;
      }

      const len: number = response.length;
      for (let i: number = 0; i < len; i++) {
        const ret: TurbidityAlertLevels = {
          sensor: response[i].sensor,
          level1: response[i].level1,
          level2: response[i].level2,
          level3: response[i].level3
        };

        this.turbidityAlertLevels.push(ret);
      }
    });
  }

  //濁度&雨量リスト表示--------------------------

  // 引数の日時までの濁度予測情報で、一件でもデータが存在する最新の日時の全センサーの情報をテーブルにsetする
  //   ・取得データの基準日時を格納し、雨量画像生成関数をcall
  //   ・濁度用詳細ページへ渡すデータも格納している
  //   ・地図上にセンサーをプロットする
  getAllTurbidityAndRainfallListByCityid(date: Date): void {
    // 濁度雨量
    const dataarray: pointDataTurbidity[] = [];

    // マーカー
    this.markers = [];
    this.markerInfos = [];
    this.markerPositions = [];

    // レスポンス
    this.todoService.getAllTurbidityAndRainfallListByCityid(this.currentCityId, moment(date).format("yyyy-MM-DD HH:mm:ss")).subscribe((response: any) => {
      if (response.length == 0) {
        this.spinner.hide();
        return;
      }

      this.rainfallImageTime = response[0].date;
      // !雨量画像表示
      this.setRainfallImage();

      const len : number = response.length;
      for (let i : number = 0; i < len; i++) {
        // 雨量濁度------------------------------
        const data1: pointDataTurbidity = {
          checkedOrNot: true,
          PointDetail: "-",
          cityName: response[i].cityName,
          areaName: response[i].areaName,
          sensorName: response[i].sensorName,
          lat: Number(response[i].lat),
          lng: Number(response[i].lng),
          dateTime: response[i].date,
          mainSensorId: response[i].mainSensorid,
          turbidityNow: response[i].turbidityPredict000,
          turbidityMax: response[i].maxTurbidityPredict,
          dateTimeMax: response[i].maxDate,
          rainfallAnalytical: "無し",
          rainfallForecast060: response[i].rainfallForecast060,
          rainfallForecast120: response[i].rainfallForecast120,
          rainfallForecast180: response[i].rainfallForecast180,
          rainfallForecast240: response[i].rainfallForecast240,
          rainfallForecast300: response[i].rainfallForecast300,
          rainfallForecast360: response[i].rainfallForecast360,
          turbidityObserved: "無し",
          turbidityPredict060: response[i].turbidityPredict060,
          turbidityPredict120: response[i].turbidityPredict120,
          turbidityPredict180: response[i].turbidityPredict180,
          turbidityPredict240: response[i].turbidityPredict240,
          turbidityPredict300: response[i].turbidityPredict300,
          turbidityPredict360: response[i].turbidityPredict360,
        };
        dataarray.push(data1);

        // マーカー------------------------------

        if (response[i].lat == null || response[i].lng == null) {
          continue;
        }

        // センサーマーカー表示
        const marker: google.maps.Marker = new google.maps.Marker({
          position: { lat: Number(response[i].lat), lng: Number(response[i].lng) },
          visible: true,
          label: response[i].mainSensorid,
        });
        this.markers.push(marker);

        // センサーマーカー情報
        this.markerInfos.push({
          areaName: response[i].areaName,
          mainSensorId: response[i].mainSensorid,
          pointName: response[i].sensorName,
          lat: Number(response[i].lat),
          lng: Number(response[i].lng),
          sensorName: response[i].sensorName,
          turbidityNow: response[i].turbidityPredict000,
          turbidityMax: response[i].maxTurbidityPredict,
          dateTimeMax: response[i].maxDate,
          turbidityPredict060: response[i].turbidityPredict060,
          turbidityPredict120: response[i].turbidityPredict120,
          turbidityPredict180: response[i].turbidityPredict180,
          turbidityPredict240: response[i].turbidityPredict240,
          turbidityPredict300: response[i].turbidityPredict300,
          turbidityPredict360: response[i].turbidityPredict360
        });

        // マーカーポジション
        this.markerPositions.push({ lat: Number(response[i].lat), lng: Number(response[i].lng) });
      }

      // マーカーの中心座標に移動
      const p: google.maps.LatLngLiteral = this.getMarkersLatLng(this.markers);
      this.map.panTo(p);

      // ソート ( エリア名 → センサー名
      this.forSortData = dataarray;
      this.forSortData.sort(function (a: { areaName: string; }, b: { areaName: string; }) {
        return (a.areaName < b.areaName) ? -1 : 1;
      })
      this.forSortData.sort(function (a: { sensorName: string; }, b: { sensorName: string; }) {
        return (a.sensorName < b.sensorName) ? -1 : 1;
      })

      // 濁度&雨量リスト
      this.sampleSource4 = new MatTableDataSource<pointDataTurbidity>(this.forSortData);
      this.sampleSource4.paginator = this.paginator;
      this.sampleSource4Length = this.forSortData.length;

      this.spinner.hide();
      this.timePerformance();
    });
  }

  //画面下リストのソート(地区名と地点名のみ対応)
  mainSort(): void {
    this.sampleSource4.sort = this.sorter;
  }

  //濁度用詳細ページ-----------------------------

  // リストの"詳細"ボタン押下
  onClickTurbidity(mainSensorId: string): void {
    const sessionSendData: pointDataTurbidity | undefined = this.forSortData.find(x => x.mainSensorId == mainSensorId);

    if (sessionSendData == undefined) {
      alert("data receive  error");
      return;
    }

    sessionStorage.setItem('sessionCityNameData', sessionSendData['cityName']);
    sessionStorage.setItem('sessionAreaNameData', sessionSendData['areaName']);
    sessionStorage.setItem('sessionSensorNameData', sessionSendData['sensorName']);
    sessionStorage.setItem('sessionSensorData', sessionSendData['mainSensorId']);
    sessionStorage.setItem('filterFromDate', moment(sessionSendData['dateTime']).add(-6, 'h').format('YYYY-MM-DD HH:mm'));
    sessionStorage.setItem('filterToDate', moment(sessionSendData['dateTime']).format('YYYY-MM-DD HH:mm'));
    sessionStorage.setItem('locationHistoryTurbidity', 'db/map-turbidity');

    this.detailRouter.navigate(['db/point-detail-turbidity']), {
      queryParams: {
        pointDataSource: mainSensorId//this.newSource//todo
      }
    }
  }

  //雨量画像-------------------------------------

  // 雨量画像を生成する
  //   ・雨量&濁度情報の日時情報を利用する為、雨量&濁度情報リスト生成関数にcallする形となった
  //   ・設定ファイルから画像の座標も設定。
  //   ・実況画像を表示
  setRainfallImage(): void {
    let SW: string[]|undefined = [];
    let NE: string[] | undefined = [];

    //座標を設定ファイルから取得
    if (sessionStorage.getItem('rainfallImageSW') != null && sessionStorage.getItem('rainfallImageNE') != null && sessionStorage.getItem('rainfallImageSW') != "" && sessionStorage.getItem('rainfallImageNE') != "") {
      SW = sessionStorage.getItem('rainfallImageSW')?.split(',');
      NE = sessionStorage.getItem('rainfallImageNE')?.split(',');

      if (SW == undefined || NE == undefined) {
        SW = ["", ""];
        NE = ["", ""];

        alert("雨量画像の座標が正常に設定されていません。");
      }
    } else {
      SW = ["", ""];
      NE = ["", ""];

      alert("雨量画像の座標が正常に設定されていません。");
    }

    //雨量画像指定
    this.imageUrl = this.changeRainfallImage(this.rainfallImageTime, "0");
    this.imageBounds = new google.maps.LatLngBounds(new google.maps.LatLng(parseFloat(SW[0]), parseFloat(SW[1]), false), new google.maps.LatLng(parseFloat(NE[0]), parseFloat(NE[1]), false));
  }

  // 雨量画像を変更する
  //   ・引数の日時と時差から雨量画像を変更する
  //   ・濁度情報窓に対してaddEventしている
  changeRainfallImage(value: string, timeDIff: string | null): string {
    const timeA : string = moment(value).format('/yyyy/MM/DD/HH/');
    const timeB : string = moment(value).format('yyyyMMDDHHmm');

    if (timeDIff == null) {
      timeDIff = "0";
    }

    // 予測時刻
    let timeDiffStr : string = String(parseInt(timeDIff) * 60);
    if (timeDIff === "0") {
      timeDiffStr = "000";
    } else if (timeDIff === "1") {
      timeDiffStr = "0" + timeDiffStr;
    }

    // 地域番号
    const areaCode: string | null = sessionStorage.getItem('areaCode');
    if (areaCode == null || areaCode == "") {
      alert("雨量画像の地域番号が正常に設定されていません。");
    }

    this.setMapTitle(timeDIff);

    return "https://rainfall-image.s3.us-east-2.amazonaws.com/" + areaCode + timeA + areaCode + "_" + timeB + "_" + timeDiffStr + ".png";
  }

  //日時絞り込み---------------------------------

  // 
  get isSensor(): boolean {
    return false;
  }

  //日付指定降雨濁度グラフ用
  dateFilterTurbidity(): void {
    this.spinner.show();

    const baseDate: string = moment(new Date(this.dataFormGroupTurbidity.value.datetime)).format('yyyy-MM-DD HH:mm:ss');

    //日付入力欄のチェック(リリース時コメントアウト)
    if (baseDate == 'Invalid date' || this.dataFormGroupTurbidity.value.datetime == null) {
      alert("日時が入力されていません。");
      this.spinner.hide();
      return;
    }

    this.dateFilterMode = true;

    this.timePerformance();

    if (this.dataFormGroupTurbidity.value.datetime !== undefined) {
      this.getAllTurbidityAndRainfallListByCityid(new Date(this.dataFormGroupTurbidity.value.datetime));
    }
  }

  // 絞り込みクリア
  dateFilterClearTurbidity(): void {
    this.timePerformance();
    this.spinner.show();

    this.dateFilterMode = false;

    this.getAllTurbidityAndRainfallListByCityid(new Date());
  }

  //濁度詳細窓-----------------------------------

  // 現在濁度と予測最大濁度関連のますを着色する
  //   ・画面仕様に応じて、無色, 黄色, オレンジで対応
  //   ・level3は仕様上用いられていない
  setColor(value: number, level3: number | null, level2: number | null, level1: number | null): string {
    if (level3 == null || level2 == null || level1 == null) {
      return "";
    }

    if (value >= level2) {
      return ORANGE;
    } else if (value >= level1) {
      return YELLOW;
    }
    else {
      return "";
    }
  }

  // 閾値によって着色される時間毎のますを着色する
  //   ・引数の値の範囲で着色している。
  //   ・閾値に対してのだいなりしょうなりは他仕様と同様 202406時点
  setAlertColor(dataAlert: HTMLElement, content: MarkerInfo, lower: number | null, upper: number | null, color: string): void {
    dataAlert.style.backgroundColor = "";

    if (lower == null || upper == null) {
      return;
    }

    if (dataAlert.getAttribute("value") == "0") {
      if (Number(content.turbidityNow) >= lower && Number(content.turbidityNow) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "1") {
      if (Number(content.turbidityPredict060) >= lower && Number(content.turbidityPredict060) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "2") {
      if (Number(content.turbidityPredict120) >= lower && Number(content.turbidityPredict120) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "3") {
      if (Number(content.turbidityPredict180) >= lower && Number(content.turbidityPredict180) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "4") {
      if (Number(content.turbidityPredict240) >= lower && Number(content.turbidityPredict240) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "5") {
      if (Number(content.turbidityPredict300) >= lower && Number(content.turbidityPredict300) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
    else if (dataAlert.getAttribute("value") == "6") {
      if (Number(content.turbidityPredict360) >= lower && Number(content.turbidityPredict360) < upper) {
        dataAlert.style.backgroundColor = color;
      }
    }
  }

  // クリックする事で雨量画像の予測時刻を変更するイベントを付与
  //   ・雨量画像を変更するには都度elementを再生成する必要あり
  setAlertEvent(thisClass: any, val: string|null): void {
    // 画像の文字列を変更
    thisClass.imageUrl = thisClass.changeRainfallImage(thisClass.rainfallImageTime, val);

    // 現在の雨量画像を削除
    const currentRainfallImage : Element | null = document.querySelector('map-ground-overlay');
    currentRainfallImage?.remove();

    // 雨量画像を再生成
    const gMap : Element | null = document.querySelector('google-map');
    const newRainfallImage : HTMLElement = document.createElement("map-ground-overlay");

    // 画像とタイトルを更新
    gMap!.appendChild(newRainfallImage);
    thisClass.setMapTitle(thisClass.rainfallImageTime, val);
  }

  // 濁度詳細窓を開くと読み込まれる
  //   ・着色とイベント付与がメイン
  public openInfo(marker: MapMarker, content: MarkerInfo): void {
    //表示中の窓を閉じ新規に表示
    this.infoWindow.close();
    this.infoWindow.open(marker);

    // 閾値から対象のsensorIdを抽出
    const thisAlertLevel: TurbidityAlertLevels = this.turbidityAlertLevels.find(x => x.sensor == content.mainSensorId) ?? {
      sensor: null,
      level1: null,
      level2: null,
      level3: null
    };

    //ヘッダ(タイトルます)
    const tableHead : HTMLElement | null = document.getElementById("markerth");
    tableHead!.textContent = content.sensorName;

    //予測最大値と時刻の値と色
    const dataNow : HTMLElement | null = document.getElementById("markertdnow");
    const dataMax : HTMLElement | null = document.getElementById("markertdmax");
    const dataMaxDate: HTMLElement | null = document.getElementById("markertdmaxdate");

    // 値を入力
    dataNow!.textContent = String(content.turbidityNow);
    dataMax!.textContent = String(content.turbidityMax);
    dataMaxDate!.textContent = content.dateTimeMax;

    // 色を生成
    const nowColor: string = this.setColor(Number(dataNow?.textContent), thisAlertLevel!.level3, thisAlertLevel!.level2, thisAlertLevel!.level1);
    const predictColor: string = this.setColor(Number(dataMax?.textContent), thisAlertLevel!.level3, thisAlertLevel!.level2, thisAlertLevel!.level1);

    // 着色
    dataNow!.style.backgroundColor = nowColor;
    dataMax!.style.backgroundColor = predictColor;
    dataMaxDate!.style.backgroundColor = predictColor;

    const thisClass = this;

    //アラートによって色の変わるます
    const dataAlert : NodeListOf<HTMLElement> = document.getElementsByName("markertda1");
    const len: number = dataAlert.length;
    for (let i : number = 0; i < len; i++) {
      this.setAlertColor(dataAlert[i], content, thisAlertLevel!.level2, Number.MAX_SAFE_INTEGER, ORANGE);

      dataAlert[i].addEventListener('click', function () {
        thisClass.setAlertEvent(thisClass, this.getAttribute("value"));
      }, false);
    }
    const dataAlert2: NodeListOf<HTMLElement> = document.getElementsByName("markertda2");
    for (let i : number = 0; i < len; i++) {
      this.setAlertColor(dataAlert2[i], content, thisAlertLevel!.level1, thisAlertLevel!.level2, YELLOW);

      dataAlert2[i].addEventListener('click', function () {
        thisClass.setAlertEvent(thisClass, this.getAttribute("value"));
      }, false);
    }
    const dataAlert3: NodeListOf<HTMLElement> = document.getElementsByName("markertda3");
    for (let i : number = 0; i < len; i++) {
      this.setAlertColor(dataAlert3[i], content, Number.MIN_SAFE_INTEGER, thisAlertLevel!.level1, BLUE);

      dataAlert3[i].addEventListener('click', function () {
        thisClass.setAlertEvent(thisClass, this.getAttribute("value"));
      }, false);
    }

    //番号ます
    const dataTime: NodeListOf<HTMLElement> = document.getElementsByName("markertdswitch");
    for (let i : number = 0; i < len; i++) {
      dataTime[i].addEventListener('click', function () {
        thisClass.setAlertEvent(thisClass, this.textContent);
      }, false);
    }
  }

  //マップタイトル-------------------------------

  // 地図左上のタイトルを変更する
  //   ・angular上で翻訳する為に、日時(yyyy/MM/dd)を文字列に含んではいけない
  //   ・上の問題を解決する為にelementが複数となっている
  setMapTitle(timediff: string | null): void {
    const mapTitle1 : HTMLElement | null = document.getElementById("mapTitle1");
    const mapTitle2 : HTMLElement | null = document.getElementById("mapTitle2");
    const mapTitle3 : HTMLElement | null = document.getElementById("mapTitle3");
    const mapTitle4 : HTMLElement | null = document.getElementById("mapTitle4");

    const len: number = this.titleLangArray.length;
    for (let i : number = 0; i < len; i++) {
      if (String(i) == timediff) {
        mapTitle1!.textContent = moment(this.rainfallImageTime).format('YYYY/MM/DD');
        mapTitle2!.textContent = moment(this.rainfallImageTime).format('(dd)');
        mapTitle3!.textContent = moment(this.rainfallImageTime).format('HH:mm');
        mapTitle4!.textContent = this.titleLangArray[i];
      }
    }
  }

  //地図上のセンサープロット---------------------

  //チェックボックス操作イベント
  checkedOrNot(checked: boolean, cbindex: number, mainSensorId: string): void {
    // マーカー表示切り替え
    this.visibleMarker(checked, mainSensorId);
  }

  //ヘッダー部分のチェック動作
  isAllPointSelect(isChecked: boolean): void {
    // マーカー表示切り替え
    this.visibleMarker(isChecked);

    //チェックを付けた場合(全選択)
    if (isChecked == true) {
      for (let i : number = 0; i < this.sampleSource4Length; i++) {
        this.forSortData[i].checkedOrNot = true;
      }

      this.sampleSource4 = new MatTableDataSource<pointDataTurbidity>(this.forSortData);
      this.sampleSource4.paginator = this.paginator;
      this.sampleSource4Length = this.forSortData.length;

      this.headerCheckBox = true;
    }
    //チェックを外した場合(未選択)
    else {
      for (let i : number = 0; i < this.sampleSource4Length; i++) {
        this.forSortData[i].checkedOrNot = false;
      }

      this.sampleSource4 = new MatTableDataSource<pointDataTurbidity>(this.forSortData);
      this.sampleSource4.paginator = this.paginator;
      this.sampleSource4Length = this.forSortData.length;

      this.headerCheckBox = false;
    }
  }

  // マーカー表示非表示
  public visibleMarker(visible: boolean, mainSensorId: string = ""): void {
    let id: any;

    const len: number = this.markers.length;
    for (let i : number = 0; i < len; i++) {
      id = this.markers[i].getLabel();

      if ((mainSensorId == null || mainSensorId.length <= 0) || (mainSensorId != null && id == mainSensorId)) {
        this.markers[i].setVisible(visible);
      }
    }
  }

  // マーカーの中心座標
  public getMarkersLatLng(markers: google.maps.Marker[]): google.maps.LatLngLiteral {
    if (markers == null || markers.length <= 0) {
      return { lat: this.map.getCenter().lat(), lng: this.map.getCenter().lng() };
    }

    let minLat: number = 0;
    let minLng: number = 0;
    let maxLat: number = 0;
    let maxLng: number = 0;

    const len: number = markers.length;
    for (let i : number = 0; i < len; i++) {
      const mkLat: number = markers[i].getPosition() ? markers[i].getPosition()!.lat() : 0;
      const mkLng: number = markers[i].getPosition() ? markers[i].getPosition()!.lng() : 0;

      if (minLat == 0 || minLat >= mkLat) {
        minLat = mkLat;
      }
      if (minLng == 0 || minLng >= mkLng) {
        minLng = mkLng;
      }
      if (maxLat == 0 || maxLat <= mkLat) {
        maxLat = mkLat;
      }
      if (maxLng == 0 || maxLng <= mkLng) {
        maxLng = mkLng;
      }
    }
    return { lat: minLat + ((maxLat - minLat) / 2), lng: minLng + ((maxLng - minLng) / 2) };
  }

  //デバッグ用-----------------------------------

  // デバッグ時log
  outputLog(title: string, object: any): void {
    // デバッグ時(開発環境)のみ
    if (environment.production == true) {
      return;
    }

    // 時間
    const timeLog : string = "[" + moment(new Date()).format("yyyy/MM/DD HH:mm:ss") + "] ";

    // string, number, boolだったら
    if (typeof (object) === "string" || typeof (object) === "number" || typeof (object) === "boolean") {
      console.log(timeLog + title + " : " + object);
      return;
    }

    // null, object, undefined, function
    console.log(timeLog +  title + " ↓");
    console.log(object);
  }

  // 時間計測
  timePerformance(): void {
    // デバッグ時(開発環境)のみ
    //if (environment.production == true) {
    //  return;
    //}

    // 時間
    const timeLog : string = "[" + moment(new Date()).format("yyyy/MM/DD HH:mm:ss") + "] ";

    if (this.startTime == null) {
      this.startTime = performance.now(); // 開始時間

      console.log(timeLog + "計測開始");
    }
    else {
      this.endTime = performance.now(); // 終了時間
      console.log(timeLog + "計測終了 : " + ((this.endTime - this.startTime) * 0.001).toFixed(3) + "秒");

      this.startTime = null;
    }
  }
  //---------------------------------------------
}


//プライベート関数---------------------------------------------------------------------------------
// #region private関数

// #endregion
